
/*
      ___      _             _          
     / __|__ _| |___ _ _  __| |__ _ _ _ 
    | (__/ _` | / -_) ' \/ _` / _` | '_|
     \___\__,_|_\___|_||_\__,_\__,_|_|  

    All the following styles are applied
    to the calendar component.
*/
    .calendar { display: flex; max-width: 300px }
    .calendar .calendarTime { border-radius: 5px 0 0 5px; max-width: 85px; min-width: unset }
    .calendar .calendarDate { border-left: 0px; border-radius: 0 5px 5px 0; margin-right: 5px; max-height: 35px; min-height: 35px }
    .inputField.calendarDate,
    .inputField.calendarTime { margin-bottom: 5px }