/*
     ___                _      __ _     _    _ 
    |_ _|_ _  _ __ _  _| |_   / _(_)___| |__| |
     | || ' \| '_ \ || |  _| |  _| / -_) / _` |
    |___|_||_| .__/\_,_|\__| |_| |_\___|_\__,_|
             |_|                               
    
    All the following styles are applied
    to the all input field components.
    Thus, this applies to every component
    in the /inputs folder

    Table of contents.

    -  # General
    -  # Dropdown
    -  # Sizes
    -  # Template
*/
    ::placeholder {
        color: var(--grey-70);
        font-family: inherit;
        font-size: 12px;
        font-weight: inherit;
    }

/* # General */

    .inputFieldWrapper { 
        height: 35px; 
        margin-bottom: 5px; 
        margin-right: 5px; 
        max-width: 300px; 
        position: relative;
    }
    .inputFieldWrapper .icon {
        color: var(--grey-90);
        height: 20px;
        left: 10px;
        opacity: .5;
        position: absolute;
        top: 8px;
        width: 20px;
    }
    .inputFieldWrapper .inputFieldCtaIcon {
        border-radius: 5px;
        color: var(--grey-90);
        cursor: pointer;
        height: 29px;
        left: unset;
        opacity: .75;
        padding: 5px;
        right: 5px;
        top: 50%;
        transform: translate(0, -50%);
        width: 29px;
    }
    .inputFieldWrapper .inputFieldCtaIcon:hover { background-color: var(--grey-5) }
    .inputField {
        border: none;
        border: 1px solid var(--grey-20);
        box-shadow: 1px 1px 3px rgba(0,0,0,.1);
        border-radius: 5px;
        font-family: inherit;
        font-size: 12px;
        height: 35px;
        max-width: 300px;
        min-width: 150px;
        padding: 10px;
        width: 100%;
    }
    .inputField:focus { border: 1px solid var(--main) }
    .inputField:invalid { box-shadow: none }
    .inputField:disabled { box-shadow: none }
    .inputField.error { border: 2px solid var(--error) }

/* # Dropdown */

    .inputFieldDropdown {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 20px rgba(0,0,0,.1);
        color: var(--grey-50);
        display: flex;
        flex-direction: column;
        font-size: 12px;
        height: max-content;
        left: 0;
        max-height: 190px;
        max-width: 300px;
        overflow: auto;
        padding: 5px 0;
        position: absolute;
        right: 0;
        text-transform: capitalize;
        top: 35px;
        width: 100%;
        z-index: 800;
    }
    .inputFieldDropdown .inputFieldDropdownOption { cursor: pointer; display: flex; padding: 10px }
    .inputFieldDropdown .inputFieldDropdownOption:hover { background-color: var(--grey-5) }
    .inputFieldDropdown .inputFieldDropdownOption span { min-width: max-content; padding: 0 5px }
    .inputFieldDropdown .inputFieldDropdownOption span::after { content: '-'; padding-left: 10px }
    .inputFieldDropdown .inputFieldDropdownOption span:last-child::after { content: unset; padding-left: 10px }
    .inputFieldWrapper .iconDropdown {
        left: -2px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
    }
    .inputFieldWrapper .iconDropdown .icon {
        left: unset;
        position: relative;
        top: unset;
        width: 30px !important;
    }
    .inputFieldWrapper .iconDropdown .iconDropdownList .icon { opacity: 1; }

/* # Sizes */

    .inputFieldWrapper.S { max-width: 75px; min-width: 75px }
    .inputFieldWrapper.S .inputField { max-width: 75px; min-width: 75px }

    .inputFieldWrapper.M { max-width: 125px; min-width: 125px }
    .inputFieldWrapper.M .inputField { max-width: 125px; min-width: 125px }

/* # Template 2 */

    .inputField.temp_2 { border: none; box-shadow: none }
    .inputField.temp_2:focus { border: none }

    
