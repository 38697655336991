.form__group {
    position: relative;
    margin-top: 10px;
    color: var(--grey-50);
  }
  .form__field {
    outline: 0;
    border: 1px solid var(--grey-20);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .1);
    border-radius: 5px;
    font-family: inherit;
    font-size: 12px;
    height: 35px;
    max-width: 300px;
    min-width: 150px;
    padding: 10px;
    width: 100%;
  }
  .form__field::placeholder {
    color: white;
  }
  .form__field:placeholder-shown ~ .form__label {
    font-size: 12px;
    cursor: text;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
  }
  .form__label {
    position: absolute;
    cursor: text;
    top: 50%;
    transform: translate(0, -50%);
    left: 10px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    pointer-events: none;
  }
  .has_icon { left: 35px !important;}

  .form__label::after {
    position: absolute;
    top: 7px;
    content: "";
    background-color: white;
    width: calc(100% + 9px);
    height: 2px;
    left: -3px;
    z-index: -1;
  }
    
    .form__field:focus {
    font-weight: 700;
    border-image-slice: 1;
  }
  .form__field:focus ~ .form__label {
    position: absolute;
    top: 1px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: var(--grey-90);
  }
  .form__field:not(:placeholder-shown) {
    padding-bottom: 6px;
    font-weight: 700;
    border-image-slice: 1;
  }
  .form__field:not(:placeholder-shown) ~ .form__label {
    position: absolute;
    top: 1px;
    display: block;
    transition: 0.2s;
    font-size: 12px;
    color: var(--grey-90);
  }


  .form__group.datefield .form__label {
    top: 1px;
  }