/*
     _____     _    _     
    |_   _|_ _| |__| |___ 
      | |/ _` | '_ \ / -_)
      |_|\__,_|_.__/_\___|
        
    All the following style are applied
    to the table component.

*/
.esnTableWrapper { height: 100%; width: 100% }
.esnTableWrapper .esnTable { 
    border: 1px solid var(--grey-10); 
    border-radius: 5px;
    overflow: auto; 
    width: 100%;
}
.esnTableWrapper table { border-collapse: collapse; font-size: 11px; width: 100% }
.esnTableWrapper th { 
    color: var(--grey-50);
    font-size: 13px;
    font-weight: bold;
    max-width: 150px;
    overflow: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.esnTableWrapper th, 
.esnTableWrapper td {
    border-bottom: 1px solid var(--grey-10);
    border-right: 1px solid var(--grey-10);
    height: 30px;
    padding: 3px 5px;
    text-align: left;
}
.esnTableWrapper th:last-child, .esnTableWrapper td:last-child {
    border-right: none;
}
.esnTableWrapper tr:hover,
.esnTableWrapper tr.active { background-color: var(--grey-5) }
.esnTableWrapper thead tr:hover,
.esnTableWrapper thead tr.active { background-color: transparent }
.esnTableWrapper td .buttonToggle span { margin: 0 }
.esnTableWrapper .esnTable .icon {
    color: var(--grey-90); 
    cursor: pointer;
    height: 25px;
    min-height: 25px;
    min-width: 25px;
    padding: 5px;
    width: 25px;
}
.esnTableWrapper .esnTable .icon.true { color: var(--success) }
.esnTableWrapper .esnTable .icon.false { color: var(--error) }
.esnTableWrapper td img { border-radius: 5px; max-height: 75px; max-width: 75px }
.esnTableWrapper .td .uploadField { border-radius: 5px; max-height: 55px; max-width: 55px; min-width: unset; margin-top: 0;}

.esnTableWrapper .tableFilter {
    display: flex;
    justify-content: right;
    margin-bottom: 10px;
    margin-left: auto;
    position: relative;
    width: 110px;
}
.esnTableWrapper .tableFilterMenuWrapper {
    right: 10px;
    position: absolute;
    top: 45px;
    transform: scale(0);
    transform-origin: top;
    background-color:  white;
    box-shadow: 0 0 25px rgba(0,0,0,.1);
    border-radius: 10px;
    padding: 25px;
    height: 270px;
    z-index: 999;
    width: 300px;
}
.esnTableWrapper .tableFilterMenuWrapper .tableFilterMenu {
    height: 100%;
    overflow: auto;
    padding-right: 5px;
}
.esnTableWrapper .tableFilter:hover .tableFilterMenuWrapper {
    transform: scaleY(1);
}
.esnTableWrapper .checkBox { margin: 0 }
.FlexTableRowOptions { display: flex }