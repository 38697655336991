/*
      ___ _           _   _             
     / __| |_  ___ __| |_| |__  _____ __
    | (__| ' \/ -_) _| / / '_ \/ _ \ \ /
     \___|_||_\___\__|_\_\_.__/\___/_\_\

    All the following styles are applied
    to the checkBox component.
*/
    .checkBox { 
        border-radius: 5px;
        cursor: pointer; 
        margin-bottom: 10px; 
        max-width: 250px;
        padding: 5px;
    }
    .checkBox:hover { background-color: var(--grey-5) }
    .checkBox.disabled:hover { background-color: transparent }
    .checkBox input { display: none }
    .checkBox label { align-items: center; cursor: pointer; display: flex; flex-direction: row }
    .checkboxBox {
        border: 1px solid var(--grey-50);
        border-radius: 5px;
        height: 15px;
        margin-right: 10px;
        min-height: 15px;
        min-width: 15px;
        width: 15px;
    }
    .checkBox span {
        align-items: flex-end;
        color: var(--grey-90);
        cursor: pointer;
        display: flex;
        font-size: 12px;
        padding-top: 2px;
    }
    .checkboxBoxSelected {
        background-color: var(--grey-20);
        border-radius: 3px;
        height: 100%;
        transform: scale(0);
        transform-origin: center;
        transition: .25s;
        width: 100%;
    }
    .checkboxBox:hover .checkboxBoxSelected,
    .checkBox:hover .checkboxBoxSelected { transform: scale(.75) }
    .checkBox.active .checkboxBox .checkboxBoxSelected { background-color: var(--sub); transform: scale(1) }
    .checkBox.active .checkboxBox { border: 1px solid var(--sub) }
    .checkBox.disabled { cursor: default; opacity: .5 }
    .checkBox.disabled span { cursor: default }
    .checkBox.disabled .checkboxBox:hover .checkboxBoxSelected { transform: scale(0) }
    .checkBox.disabled:hover .checkboxBoxSelected { transform: scale(0) }
    .checkBox.active.disabled:hover .checkboxBoxSelected { transform: scale(1) }

    .checkBox.right {  display: flex; justify-content: flex-end; }
    .checkBox.right .checkboxBox {  margin-right: 0; margin-left: 10px;}