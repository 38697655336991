
/*
     ___      _        _      __ _     _    _ 
    / __| ___| |___ __| |_   / _(_)___| |__| |
    \__ \/ -_) / -_) _|  _| |  _| / -_) / _` |
    |___/\___|_\___\__|\__| |_| |_\___|_\__,_|
    
    All the following styles are applied
    to the select field component.

*/

/* # General */

    .selectField {
        background-color: white;
        border-radius: 5px;
        font-size: 13px;
        height: max-content;
        margin: 5px; 
        max-width: 300px;
        min-width: 70px;
        position: relative;
    }
    .selectField .selectFieldSelected {
        align-items: center;
        border-radius: 5px; 
        border: 1px solid var(--grey-20);
        box-shadow: 1px 1px 3px rgba(0,0,0,.1);
        color: var(--grey-90);
        cursor: pointer;
        display: flex;
        min-height: 35px;
        justify-content: space-between;
        padding: 5px 10px;
    }
    .selectField.disabled { background-color: #e3e3e3 }
    .selectField.disabled .selectFieldSelected { box-shadow: none; color: var(--grey-80); cursor: default }
    .selectField.disabled:hover .selectFieldOptions { opacity: 0; transform: scaleY(0) }
    .selectField .selectFieldSelected .icon { color: var(--grey-50); height: 12px; margin-left: 10px; width: 12px }
    .selectField .selectFieldOptions {
        background-color: white;
        border-radius: 5px;
        border: 1px solid var(--grey-20);
        box-shadow: 1px 1px 3px rgba(0,0,0,.1);
        color: var(--grey-50);
        left: 0;
        min-width: 100%;
        opacity: 0;
        padding: 5px 0;
        position: absolute;
        top: 40px;
        transform: scaleY(0);
        transform-origin: top center;
        transition: .5s;
        z-index: 700;
    }
    .selectField .selectFieldOption { cursor: pointer; display: flex; padding: 5px 10px }
    .selectField .selectFieldOption > div { flex: 1 }
    .selectField .selectFieldOption:hover { background-color: var(--grey-10) }
    .selectField.active .selectFieldOptions { opacity: 1; transform: scaleY(1) }
    
/* # Template 2 */

    .selectField.temp_2 .selectFieldSelected { border: none; box-shadow: none }