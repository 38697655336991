  /*
       _   _         _   _             
      /_\ | |___ _ _| |_| |__  _____ __
     / _ \| / -_) '_|  _| '_ \/ _ \ \ /
    /_/ \_\_\___|_|  \__|_.__/\___/_\_\
    
    All the following styles are applied
    to the alertbox component.
*/
    .alertBoxWrapper {
        align-items: center;
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: fixed;
        right:  0;
        top: 0;
        z-index: 1000;
    }
    .alertBoxWrapper .alertBoxBg {
        align-items: center;
        background-color: rgba(0,0,0,.5);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;
    }
    .alertBoxWrapper .alertBox {
        align-items: center;
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,.1);
        display: flex;
        flex-direction: column;
        height: 150px;
        justify-content: center;
        padding: 25px;
        position: relative;
        width: 325px;
        z-index: 1001;
    }
    .alertBoxWrapper .alertBoxButtons { display: flex; height: 45px; justify-content: center }
    .alertBoxWrapper .alertBoxButtons .button { margin: 0; opacity: 1 }
    .alertBoxWrapper .icon.cancel {
        cursor: pointer;
        height: 15px;
        position: absolute;
        right: 10px;
        top: 10px;
        width: 15px;
    }
    .alertBoxWrapper span { font-size: 18px; margin-bottom: 15px }