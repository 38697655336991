/*
     ___                  _                  _                 
    |_ _|__ ___ _ _    __| |_ _ ___ _ __  __| |_____ __ ___ _  
     | |/ _/ _ \ ' \  / _` | '_/ _ \ '_ \/ _` / _ \ V  V / ' \ 
    |___\__\___/_||_| \__,_|_| \___/ .__/\__,_\___/\_/\_/|_||_|
                                   |_|                         
    
    All the following styles are applied
    to the icon dropdown component.
*/
    .iconDropdown {
        align-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 35px;
        position: relative;
        width: 35px;
    }
    .iconDropdown .iconDropdownCurrent {
        background-color: transparent;
        border-radius: 5px; 
        position: relative; 
        transition: 0.25s; 
        width: max-content;
        z-index: 0; 
    }
    .iconDropdown.active .iconDropdownCurrent { background-color: var(--grey-10) }
    .iconDropdown .iconDropdownCurrent:hover { background-color: var(--grey-5) }
    .iconDropdown .iconDropdownCurrent .icon { 
        color: var(--grey-90); 
        cursor: pointer; 
        height: 35px; 
        padding: 5px; 
        width: 35px;
    }
    .iconDropdown .iconDropdownList {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,.1);
        color: var(--grey-50);
        display: flex;
        flex-wrap: wrap;
        max-height: 200px;
        min-height: 200px;
        opacity: 0;
        overflow: auto;
        position: absolute;
        top: 35px;
        transform: scale(0);
        transition: 0.25s;
        width: 300px;
        z-index: 30;
    }
    .iconDropdown.active .iconDropdownList { opacity: 1; transform: scale(1) }
    .iconDropdown .iconDropdownList span { display: inline-block }
    .iconDropdown .iconDropdownList span.active { color: var(--sub) }
    .iconDropdown .iconDropdownList .icon {
        cursor: pointer;
        height: 30px;
        margin: 5px;
        max-height: 30px;
        max-width: 30px;
        min-height: 30px;
        min-width: 30px;
        padding: 5px;
        transition: 0.25s;
        width: 30px;
    }
    .iconDropdown .iconDropdownList .icon:hover { background-color: var(--grey-10) }