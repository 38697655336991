
  /*
       _                    _ _          
      /_\  __ __ ___ _ _ __| (_)___ _ _  
     / _ \/ _/ _/ _ \ '_/ _` | / _ \ ' \ 
    /_/ \_\__\__\___/_| \__,_|_\___/_||_|

    All the following styles are applied
    to the accordion component.
*/
    .accordion {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,.1);
        display: flex;
        flex-flow: column nowrap;
        width: 250px;
    }
    .accordion .accordionItem { border-bottom: 1px solid var(--grey-20); display: flex; flex-flow: column nowrap }
    .accordion .accordionItem:last-child { border-bottom: none }
    .accordion .accordionTitle {
        align-items: center;
        color: var(--grey-80);
        cursor: pointer;
        display: flex;
        flex: 1;
        justify-content: space-between;
        padding: 15px;
    }
    .accordion .accordionItem:first-child .accordionTitle { border-radius: 5px 5px 0 0 }
    .accordion .accordionItem:last-child .accordionTitle { border-radius: 0 0 5px 5px }
    .accordion .accordionTitle.active { color: var(--sub) }
    .accordion .accordionTitle:hover { background-color: var(--grey-5) }
    .accordion .accordionTitle .icon { height: 19px; transition: .25s; width: 19px }
    .accordion .accordionItemContent { align-items: center; display: flex; padding: 20px }

    /* Template 2 */
    .accordion.temp_2 { box-shadow: none }
    .accordion.temp_2 .accordionTitle.active .icon { transform: rotate(90deg) }