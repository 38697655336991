
/* 
    _            _                  _     _                   
   /_\    _ _   (_)  _ __    __ _  | |_  (_)  ___   _ _    ___
  / _ \  | ' \  | | | '  \  / _` | |  _| | | / _ \ | ' \  (_-<
 /_/ \_\ |_||_| |_| |_|_|_| \__,_|  \__| |_| \___/ |_||_| /__/
                                                              
*/

    /* Fade animations */
    @keyframes fade-in { 0% { opacity: 0 } 100% { opacity: 1 } }
    .fade_in { animation: fade-in .5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both }

    /* Slide animations */
    @keyframes slide-in-right {
        0% { opacity: 1; transform: translateX(1000px) } 
        100% { opacity: 1; transform: translateX(0) }
    }
    .slide-in-right { animation: slide-in-right .75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both }
    @keyframes slide-in-left {
        0% { opacity: 1; transform: translateX(-1000px) }
        100% { opacity: 1; transform: translateX(0) }
    }
    .slide-in-left { animation: slide-in-left .75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both }
    
    /* Scale animations */
    @keyframes scale-in-center {
        0% { transform: scale(0); opacity: 1 }
        100% { transform: scale(1); opacity: 1 }
    }
    .scale_in { animation: scale-in-center 0.3s cubic-bezier(0.175, 0.885, 0.320, 1.275) both }
    
    /* Login animations */
    @keyframes login-show {
        0% { transform: scale(0.9) translate(-60px, 0); z-index: 0 }
        50% { transform: scale(1) translate(-52%, 0); z-index: 0 }
        51% { z-index: 1 }
        100% { transform: scale(1) translate(0, 0) ; z-index: 1 }
    }
    @keyframes login-show-mobile {
        0% { transform: scale(0.9) translate(-40px, 0); z-index: 0 }
        50% { transform: scale(1) translate(-52%, 0); z-index: 0 }
        51% { z-index: 1 }
        100% { transform: scale(1) translate(0, 0) ; z-index: 1 }
    }
    .login_show { animation: login-show 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
    @keyframes login-hide {
        0% { transform: scale(1) translate(-60px, 0); z-index: 1 }
        50% { transform: scale(0.9) translate(-52%, 0); z-index: 1 }
        51% { z-index: 0 }
        100% { transform: scale(0.9) translate(-60px, 0); z-index: 0 }
    }
    @keyframes login-hide-mobile {
        0% { transform: scale(1) translate(-40px, 0); z-index: 1 }
        50% { transform: scale(0.9) translate(-52%, 0); z-index: 1 }
        51% { z-index: 0 }
        100% { transform: scale(0.9) translate(-40px, 0); z-index: 0 }
    }
    .login_hide { animation: login-hide 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
    @keyframes login-reset-show {
        0% { transform: scale(0.9) translate(60px, 0); z-index: 0 }
        50% { transform: scale(1) translate(52%, 0); z-index: 0 }
        51% { z-index: 1 }
        100% { transform: scale(1) translate(0, 0); z-index: 1 }
    }
    @keyframes login-reset-show-mobile {
        0% { transform: scale(0.9) translate(40px, 0); z-index: 0 }
        50% { transform: scale(1) translate(52%, 0); z-index: 0 }
        51% { z-index: 1 }
        100% { transform: scale(1) translate(0, 0); z-index: 1 }
    }
    .login_reset_show { animation: login-reset-show 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
    @keyframes login-reset-hide {
        0% { transform: scale(1) translate(0, 0); z-index: 1 }
        50% { transform: scale(0.9) translate(52%, 0); z-index: 1 }
        51% { z-index: 0 }
        100% { transform: scale(0.9) translate(60px, 0); z-index: 0 }
    }
    @keyframes login-reset-hide-mobile {
        0% { transform: scale(1) translate(0, 0); z-index: 1 }
        50% { transform: scale(0.9) translate(52%, 0); z-index: 1 }
        51% { z-index: 0 }
        100% { transform: scale(0.9) translate(40px, 0); z-index: 0 }
    }
    .login_reset_hide { animation: login-reset-hide 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
    @media screen and (max-width: 768px) {
        .login_show { animation: login-show-mobile 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
        .login_hide { animation: login-hide-mobile 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
        .login_reset_show { animation: login-reset-show-mobile 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
        .login_reset_hide { animation: login-reset-hide-mobile 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both }
    }
