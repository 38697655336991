
/*
     _____        _                        __ _     _    _ 
    |_   _|____ _| |_ __ _ _ _ ___ __ _   / _(_)___| |__| |
      | |/ -_) \ /  _/ _` | '_/ -_) _` | |  _| / -_) / _` |
      |_|\___/_\_\\__\__,_|_| \___\__,_| |_| |_\___|_\__,_|

    All the following styles are applied
    to the textarea field component.

*/
    .textareaFieldWrapper { margin: 0 5px 5px 0; max-width: 300px; position: relative }
    .textareaFieldWrapper .textareaField {
        border: none;
        border: 1px solid var(--grey-20);
        border-radius: 5px;
        box-shadow: 1px 1px 3px rgba(0,0,0,.1);
        color: var(--grey-90);
        font-family: inherit;
        font-size: 13px;
        height: 94px;
        margin: 0;
        max-height: unset;
        max-width: 300px;
        padding: 10px;
        resize: none;
        width: 100%;
        height: auto;
    }
    .textareaFieldWrapper .textareaField:focus { border: 1px solid var(--main) }
    .textareaFieldWrapper .textareaField:disabled { box-shadow: none }
    .textareaFieldWrapper .textareaField.error { border: 2px solid var(--error) }
    .textareaFieldWrapper .textareaFieldIcons {
        bottom: 5px;
        cursor: pointer;
        position: absolute;
        right: 5px;
        width: max-content;
    }
    .textareaFieldWrapper .textareaFieldIcons .icon {
        border-radius: 5px;
        box-sizing: content-box;
        color: var(--grey-50);
        height: 20px;
        padding: 5px;
        transition: 0.25s;
        width: 20px;
    }
    .textareaFieldWrapper .textareaFieldIcons .icon:hover{ background-color: var(--grey-5) }
    .textareaFieldWrapper .textareaFieldIcons.active .icon,
    .textareaFieldWrapper .textareaFieldIcons.active .icon:hover{ background-color: white }
    .textareaFieldWrapper .textareaFieldIcons.active .icon { border-radius: 5px 5px 0 0; box-shadow: 0 0 25px rgba(0,0,0,.1) }
    .textareaFieldWrapper .textareaFieldIconPicker {
        border-radius: 5px 0 5px 5px;
        position: absolute;
        right: 0;
        top: 30px;
        transform: scaleY(0);
        transform-origin: top;
        transition: 0.25s;
        z-index: 1;
    }
    .textareaFieldWrapper .textareaFieldIcons.active .textareaFieldIconPicker { transform: scaleY(1); }

    /* Template 2 */
    
    .textareaField.temp_2 { border: none; box-shadow: none }
    .textareaField.temp_2:focus { border: none }
    