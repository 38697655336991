/* 
  __  __               
 |  \/  |  __ _   _ __ 
 | |\/| | / _` | | '_ \
 |_|  |_| \__,_| | .__/
                 |_|   
*/
@import '~leaflet/dist/leaflet.css';
@import '~react-leaflet-markercluster/dist/styles.min.css';


#map {
  height: 100%;
  width: 100%;
  min-height: 500px;
  z-index: 0;
}

.leaflet-marker-icon {
  /* background: white; */
  /* border-radius: 5px; */
  /* display: block; */
  height: 50px !important;
  /* left: -20px; */
  /* position: relative; */
  /* top: -40px; */
  transition: 0.3s;
  width: 50px !important;
  /* border: 1px solid var(--main); */
}

/* .leaflet-marker-icon::after {
        content: '';
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid var(--main);
        transition: 0.3s;
    } */

.markerLogo {
  display: block;
  width: 90%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 auto;
}

.map_sidebar {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
  background-color: var(--grey);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mapOptions {
  background-color: white;
  border: 2px solid var(--grey-30);
  height: max-content;
  border-radius: 5px;
  padding: 5px;
  margin: 10px;
}

.mapOptions h2 {
  font-size: 20px;
  padding: 5px;
  color: var(--grey-70);
}

.mapOptions .checkBox {
  margin-bottom: 0;
}

.mapWrapper #mapDrawer .mapOptions h2 {
  padding: 10px;
}

#mapInfo {
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  left: 310px;
  min-width: 150px;
  padding: 10px;
  position: absolute;
  width: max-content;
  z-index: 9999;
  font-size: 13px;
  display: none;
  align-items: center;
}

#mapInfo .icon {
  color: var(--sub);
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  width: 20px;
}

#mapInfo p {
  margin: 0;
}

#mapConfirm {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 9999;
  border-radius: 5px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: var(--grey-10);
}

#mapConfirm .icon {
  box-sizing: content-box;
  height: 20px;
  padding: 7px;
  position: relative;
  width: 20px;
  z-index: 9;
  color: var(--grey-90);
}

#mapConfirm .icon::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--grey-10);
  opacity: 0.75;
  z-index: -1;
}

#mapConfirm .icon:hover::after {
  opacity: 0.9;
}

#mapConfirm .icon.check,
#mapConfirm .icon.bin {
  color: white;
}

#mapConfirm .icon.check::after {
  background-color: var(--success);
}

#mapConfirm .icon.bin::after {
  background-color: var(--error);
}

.map_wrapper .mapDrawerTop {
  height: 100px;
  position: relative;
  background-color: white !important;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--grey-30);
}

#logo {
  height: 4rem;
  width: -webkit-fill-available;
  margin: 1vw;
}

#mapInstructions {
  font-size: 14px;
  padding: 15px;
  color: var(--grey-90);
}

#mapInstructions ol {
  padding-left: 40px;
  margin: 14px 0;
}

#selectedNis {
  align-items: center;
  display: flex;
  width: max-content;
  font-size: 13px;
  padding: 5px 15px;
  cursor: pointer;
}

#selectedNis .icon {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  color: var(--sub);
  transition: 0.3s;
}

#selectedNis:hover .icon {
  transform: rotate(90deg);
}

.map_sidebar h2 {
  font-size: 20px;
  padding: 5px;
  color: var(--grey-70);
  margin: 0 10px;
  text-transform: capitalize;
}

.leaflet-interactive.hidden {
  display: none;
}

.leaflet-marker-icon .markerShape {
  background: white;
  border-radius: 5px;
  display: block;
  height: 50px !important;
  position: relative;
  width: 50px !important;
  border: 1px solid var(--main);
  transition: 0.3s;
  top: 0;
  z-index: 998;
}

.leaflet-marker-icon.active .markerShape {
  transform: scale(1.2);
  top: -7px;
  z-index: 999;
}

.leaflet-marker-icon.active {
  z-index: 1000 !important;
  position: relative;
}

.leaflet-marker-icon.active::after {
  bottom: -8px;
}

.markerTriangle {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

}

.addMarker {
  position: absolute;
  z-index: 9999;
  background-color: white;
  right: 10px;
  bottom: 100px;
  height: 34px;
  width: 34px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--grey-90);
  transition: .3s;
  cursor: pointer;


}

.MyMap.active {
  cursor: crosshair;
}

.addMarker:hover,
.addMarker.active {
  background-color: #f4f4f4;
}

.addMarker.active::after {
  content: 'Cancel';
  background-color: #919187;
  height: 100%;
  width: 50px;
  right: 30px;
  border-radius: 4px 0 0 4px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-right: none;
  font-size: 13px;
}

/* Style.css */
.selected-items-container {
  max-height: 34rem;
  /* Set a max height for the container */
  overflow-y: auto;
  /* Enable vertical scrolling if content exceeds max-height */
  padding-right: 10px;
  /* Optional: Add some padding to avoid scrollbar overlap */
}

/* Scrollbar styling for modern browsers */
.selected-items-container::-webkit-scrollbar {
  width: 12px;
  /* Width of the scrollbar */
}

.selected-items-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  /* Track background */
  border-radius: 6px;
  /* Optional: rounded corners */
}

.selected-items-container::-webkit-scrollbar-thumb {
  background: #ffd53d;
  /* Scrollbar thumb color (internal part) */
  border-radius: 6px;
  /* Optional: rounded corners */
}

.selected-items-container::-webkit-scrollbar-thumb:hover {
  background: #ee901e;
  /* Hover effect on the scrollbar thumb */
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* Ensures the dropdown is positioned relative to this container */
  padding: 10px;
  border-bottom: 2px solid #ddd;
  /* Subtle separation */
}

.search-input {
  height: 80%;
  width: 100%;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.search-input:hover {
  border-color: orange;
  cursor: text;
}

.search-icon {
  position: absolute;
  right: 25px;
  font-size: 22px;
  color: #666;
  cursor: pointer;
}

/* Dropdown Suggestions */
.search-dropdown {
  position: absolute;
  top: 100%;
  /* Positions it directly below the search bar */
  left: 0;
  width: 95%;
  background: white;
  border-radius: 8px;
  border: 1px solid #ccc;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  /* Prevents excessive height */
  z-index: 10;
  padding: 0;
  /* Ensure no extra padding on the dropdown container */

  margin: 2% -3% 3% 3%;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  /* Adjust padding as needed */
  margin: 0;
  /* Remove any unwanted margin */
  text-align: left;
  /* Ensure text aligns properly */
  transition: background 0.5s;
}

.dropdown-item:hover {
  background: #fec148;
  /* Hover background */
  color: black;
  /* Ensure text remains visible */
  font-weight: bold;
  /* Highlight hover effect */
}

.search-dropdown::-webkit-scrollbar {
  width: 10px;
}

.search-dropdown::-webkit-scrollbar-track {
  background: #f0f0f0;
}

.search-dropdown::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}

.search-dropdown::-webkit-scrollbar-thumb:hover {
  background: #fec148;
}