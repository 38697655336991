/*
     ___             ___          
    |_ _|__ ___ _ _ | _ ) _____ __
     | |/ _/ _ \ ' \| _ \/ _ \ \ /
    |___\__\___/_||_|___/\___/_\_\

    All the following style are applied
    to the icon box component.

*/
    .iconBox {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,.1);
        display: flex;
        height: 40px;
        margin: 5px;
        max-height: 40px;
        max-width: max-content;
        min-height: 40px;
        min-width: max-content;
        overflow: hidden;
        width: 100%;
    }
    .iconBox .icon.iconBoxIcon {
        border-right: 1px solid var(--grey-10);
        color: var(--grey-50);
        cursor: pointer;
        height: 39px;
        padding: 10px;
        width: 39px;
    }
    .iconBox .icon.iconBoxIcon:last-child { border: none }
    .iconBox .icon.iconBoxIcon:hover { background-color: var(--grey-5) }
    .iconBox .icon.iconBoxIcon.active { color: var(--sub) }