  /*
     ___         _ _        __ _     _    _ 
    | _ \__ _ __| (_)___   / _(_)___| |__| |
    |   / _` / _` | / _ \ |  _| / -_) / _` |
    |_|_\__,_\__,_|_\___/ |_| |_\___|_\__,_|

    All the following styles are applied
    to the radio field component.
*/
    .radioField {
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        max-width: 300px;
        min-width: max-content;
        padding: 5px;
    }
    .radioField:hover { background-color: var(--grey-5) }
    .radioField.disabled:hover { background-color: transparent }
    .radioField input { display: none }
    .radioField span { color: var(--grey-90); font-size: 12px }
    .radioField .radioFieldBox { margin-right: 10px }
    .radioField .radioFieldBox {
        border: 1px solid var(--grey-50);
        border-radius: 25px;
        min-height: 15px;
        min-width: 15px;
    }
    .radioField.active .radioFieldBox { background-color: var(--sub); border: 1px solid var(--sub) }
    .radioField.disabled { cursor: default; opacity: .5 }