/*
   ___         _             
  / __|  ___  | |  ___   _ _ 
 | (__  / _ \ | | / _ \ | '_|
  \___| \___/ |_| \___/ |_|  
                                         
*/
    :root {
        /* NETWORKS */

        --main: #fcb721;
        --main_soft: #FDCF6D;
        --sub: #FD8628;
        --sub_soft: #FFB175;
        --social_main: #4fbcbd;
        --social_sub: #4ED4B0;
        --esn: #76c047;
        --cn: #1394d2;
        --pn: #9e3d97;
        --kn: #e72c6a;
        --se: #010101;
        --sa: #053043;

        /* ENTITIES */

        --group: #24bad2;
        --enterprise: #c12525;
        --establishment: #e87a1a;

        /* SHADES OF GREY */

        --white: white;
        --grey: #f9f9f9;
        --grey-5: #f1f2f2;
        --grey-10: #e6e7e8;
        --grey-20: #d1d3d4;
        --grey-30: #bcbec0;
        --grey-50: #939598;
        --grey-70: #6c6d6f;
        --grey-80: #58595b;
        --grey-90: #414042;
        --grey-100: #231f20;
        --black: black;

        /* MESSAGES */

        --error: #ff6e44;
        --success: #4bb172;
        --warning: #f1ab3e;
        --info: #29B8FF;

        /* SOCIAL MEDIA */

        --facebook: #3b5998;
        --linkedin: #0077b5;
        --youtube: #ff0000;
        --google-blue: #4285F4;
        --google-red: #DB4437;
        --google-yellow: #F4B400;
        --google-green: #0F9D58;
        --googleplus: #dd4b39;
        --twitter: #1da1f2;
        /* --instagram: #405de6; */
        --instagram: linear-gradient(30deg,#8a3ab9 -50%,#fbad50 6%, #cd486b 96%, #4c68d7 100%);
        --pinterest: #bd081c;
        --skype: #00aff0;
        --tumblr: #35465c;
        --vimeo: #1ab7ea;
        --vine: #00b488;
        --whatsapp: #128c7e;
        --wordpress: #21759b;
        --snapchat: #fffc00;
        --behance: #1769ff;
        --dribbble: #444444;
        --flickr: #0063dc;
        --foursquare: #f94877;
        --deviantart: #05cc47;
        --bpost: #ef2637;

        /* EMOJIS */

        --thumbsup-emoji: #53c9e6;
        --love-emoji: #ef72cd;
        --wow-emoji: #bb7bfd;
        --happy-emoji: #ff860f;
        --sad-emoji: #3c81b6;
        --angry-emoji: #ed2504;

        /* OTHER */
        
        --favorite: #f17fe0;
        --ads: #fdb913;
        --video: #0059c7;
        --website: #080808;
        --eshop: #62b331;
        --newsletter: #00384e;
        --map: #cc2d2d;
    }