  /*
     ___          _      _   ___ _    _           
    / __| ___  __(_)__ _| | | _ (_)__| |_____ _ _ 
    \__ \/ _ \/ _| / _` | | |  _/ / _| / / -_) '_|
    |___/\___/\__|_\__,_|_| |_| |_\__|_\_\___|_|  

    All the following styles are applied
    to the social picker component.
*/
    .emoji-mart { border: none; box-shadow: 0 0 25px rgba(0,0,0,.1) }
    .emoji-mart .emoji-mart-bar:last-child,
    .emoji-mart .emoji-mart-preview { display: none }
    .emoji-mart .emoji-mart-emoji,
    .emoji-mart .emoji-mart-category .emoji-mart-emoji span { cursor: pointer }
    .emoji-mart .emoji-mart-anchor { cursor: pointer; transition: .5s }
    .emoji-mart .emoji-mart-anchor:hover { background-color: var(--grey-5) }
    .emoji-mart .emoji-mart-anchor-selected .emoji-mart-anchor-bar { background-color: var(--main) !important }
    .emoji-mart .emoji-mart-search { margin-bottom: 6px }