/*
     _   _      _              _    __ _     _    _ 
    | | | |_ __| |___  __ _ __| |  / _(_)___| |__| |
    | |_| | '_ \ / _ \/ _` / _` | |  _| / -_) / _` |
     \___/| .__/_\___/\__,_\__,_| |_| |_\___|_\__,_|
          |_|                                       
    
    All the following styles are applied
    to the upload field component.

*/
    .uploadField {
        align-items: center;
        border: 1px solid var(--grey-50);
        border-radius: 25px;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        font-size: 12px;
        height: 40px;
        justify-content: center;
        margin: 25px 5px 5px 5px;
        min-width: 175px;
        max-width: 175px;
        padding: 10px 15px;
        position: relative;
        transition: .5s;
        width: 100%;
    }
    .uploadField::after {
        background-color: var(--grey-20);
        bottom: 0;
        content: '';
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: .5s;
    }
    .uploadField.active::after { opacity: .2 }
    .uploadField .icon {
        height: 20px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        top: 10px;
        width: 100%;
    }
    .uploadField img,
    .uploadField video { border-radius: 5px; height: 100%; width: auto }
    .uploadField .icon path:last-child { transition: .25s }
    .uploadField.active .icon.upload_icon path:last-child,
    .uploadField:hover .icon.upload_icon path:last-child { transform: translateY(-15px) }
    .uploadField.message .icon.upload_icon path:first-child { opacity: 0 }
    .uploadField.message .icon { color: var(--sub); transform: translate(-50%, -30px); transition: .5s }
    .uploadField.message .uploadFieldMessage {
        color: var(--grey-50);
        font-style: italic;
        position: absolute;
        top: 12px;
        transition: .5s;
    }
    .uploadField.message.uploadFieldError .icon.upload_icon { opacity: 0 }
    .uploadField.message.uploadFieldError .icon.error_icon { opacity: 1 }
    .uploadField .icon.error_icon { opacity: 0; padding: 3px }
    .uploadField .uploadFieldUserMessage { color: var(--grey-90); position: absolute; top: 45px }