
/*

     ___                       __ _     _    _ 
    | _ \__ _ _ _  __ _ ___   / _(_)___| |__| |
    |   / _` | ' \/ _` / -_) |  _| / -_) / _` |
    |_|_\__,_|_||_\__, \___| |_| |_\___|_\__,_|
                  |___/                        
    
    All the following styles are applied
    to the range field component.

*/
    .rangeField { 
        height: 15px;
        margin: 5px 0; 
        max-width: 300px; 
        position: relative; 
        width: 100%;
    }
    .rangeField .rangeSlider {
        -webkit-appearance: none;
        background-color: var(--grey-20);
        border-radius: 5px;
        height: 5px;
        margin: 5px 0 10px 0;
        opacity: 0.7;
        outline: none;
        padding: 0;
        -webkit-transition: .2s;
        transition: opacity .2s;
        width: 100%;
    }
    .rangeField .rangeSlider:hover { opacity: 1 }
    .rangeField .rangeSlider::-webkit-distanceRange-thumb {
        -webkit-appearance: none;
        appearance: none;
        background-color: var(--sub);
        border-radius: 50%;
        cursor: pointer;
        height: 25px;
        width: 25px;
    }
    .rangeField .rangeSlider::-moz-range-thumb {
        background-color: var(--sub);
        border: none;
        border-radius: 50%;
        cursor: pointer;
        height: 15px;
        width: 15px;
    }
    .rangeField .rangeLabels { color: var(--grey-90); display: flex; font-size: 12px; justify-content: space-between }
    .rangeField .rangeFieldValueWrapper { align-items: center; display: flex; justify-content: center }
    .rangeField .rangeSlider:hover + .rangeFieldValueWrapper .rangeFieldValue { transform: scale(1) }
    .rangeField .rangeFieldValue {
        align-items: center;
        background-color: var(--sub);
        border-radius: 50px;
        color:white;
        display: flex;
        font-size: 12px;
        justify-content: center;
        min-height: 30px;
        min-width: 30px;
        padding: 3px;
        position: relative;
        transform: scale(0);
        transform-origin: top center;
        transition: .25s;
        width: max-content;
    }
    .rangeField .rangeFieldValue::after {
        border-bottom: 5px solid var(--sub);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        content: '';
        height: 0; 
        position: absolute;
        top: -4px;
        width: 0; 
      }