
  /*
     _____             __ _     _    _ 
    |_   _|_ _ __ _   / _(_)___| |__| |
      | |/ _` / _` | |  _| / -_) / _` |
      |_|\__,_\__, | |_| |_\___|_\__,_|
              |___/                    
    
    All the following styles are applied
    to the tagfield component.
*/
    .tagFieldWrapper { margin: 20px 10px 10px 10px; max-width: 300px }
    .tagFieldWrapper .inputField {
        padding-left: 30px;
        padding-right: 30px;
        position: relative;
        z-index: 501;
    }
    .tagFieldWrapper .dropdownWrapper {
        background-color: white;
        border-radius: 5px;
        box-shadow: 0 0 25px rgba(0,0,0,0.1);
        display: flex;
        flex-direction: column;
        left: -10px;
        padding: 55px 10px 10px 10px;
        position: absolute;
        right: -10px;
        top: -10px;
        z-index: 500;
    }
    .tagFieldWrapper .results {
        border-bottom: 1px solid var(--grey-10);
        color: var(--grey-50);
        display: flex;
        flex-direction: column;
        font-size: 13px;
        margin-bottom: 5px;
        max-height: 65px;
        overflow: auto;
    }
    .tagFieldWrapper .result { border-radius: 5px; cursor: pointer; margin-bottom: 5px; padding: 5px }
    .tagFieldWrapper span { margin-right: 5px }
    .tagFieldWrapper span:first-child::before { content: unset }
    .tagFieldWrapper span::before { content: '-'; padding-right: 5px }
    .tagFieldWrapper .result:hover { background-color: var(--grey-5) }
    .tagFieldWrapper .tags { display: flex; flex-wrap: wrap }
    .tagFieldWrapper .tag {    
        align-items: center;
        background-color: var(--sub);
        border-radius: 5px;
        color: white;
        cursor: pointer;
        display: flex;
        font-size: 12px;
        justify-content: center;
        margin: 5px;
        padding: 3px 5px;
        position: relative;
        transition: .5s;
        width: max-content;
    }
    .tagFieldWrapper.disabled .tag { cursor: default; padding: 7px 5px; }
    .tagFieldWrapper .tag::after {
        border-radius: 25px;
        bottom: 0;
        box-shadow: 0 0 10px var(--sub);
        content: '';
        left: 0;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
    }   
    .tagFieldWrapper .tag:hover { transform: scale(1.1) }
    .tagFieldWrapper.disabled .tag:hover { transform: unset }
    .tagFieldWrapper .tag .icon {
        height: 25px;
        width: 25px;
        padding: 6px;
        right: 5px;
        background-color: var(--sub);
        left: unset;
        top: unset;
        border-radius: 25px;
    }
    .tagFieldWrapper.disabled .tag .icon { display: none }
    .tagFieldWrapper .icon { 
        height: 10px;
        margin-left: 5px;
        opacity: unset; 
        position: unset;
        width: 10px;
    }
    .tagFieldWrapper .search {
        height: 15px;
        left: 4px;
        opacity: .5;
        position: absolute;
        top: 13px;
        width: 15px;
        z-index: 502;
    }
    .tagFieldWrapper .delete {
        cursor: pointer;
        height: 15px;
        left: unset;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 10px;
        width: 15px;
        z-index: 502;
    }