/*
     ___           _    _       _____               _     
    |   \ ___ _  _| |__| |___  |_   _|__  __ _ __ _| |___ 
    | |) / _ \ || | '_ \ / -_)   | |/ _ \/ _` / _` | / -_)
    |___/\___/\_,_|_.__/_\___|   |_|\___/\__, \__, |_\___|
                                         |___/|___/       

    All the following style are applied
    to the double toggle component.

*/
    .doubleToggle {
        align-items: center;
        display: flex;
        height: 20px;
        justify-content: center;
        max-width: 140px;
        min-width: 140px;
        width: 140px;
    }
    .doubleToggle .doubleToggleOption,
    .doubleToggle .doubleToggleLabel  {
        height: 20px;
        margin: 0;
        max-width: 20px;
        min-width: 20px;
        position: relative;
        transition: .25s;
        width: 20px;
    }
    .doubleToggle .doubleToggleLabel { align-items: center; display: flex; font-size: 12px }
    .doubleToggle .doubleToggleOption { background-color: var(--error) }
    .doubleToggle .doubleToggleOption.active { background-color: var(--success) }
    .doubleToggle .doubleToggleOption::after {
        bottom: 0;
        box-shadow: 0 0 10px var(--error);
        content: '';
        left: 0;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
    }
    .doubleToggle .doubleToggleOption.active::after { box-shadow: 0 0 10px var(--success) }
    .doubleToggle .doubleToggleOption0 { border-radius: 5px 0 0 5px; border-right: 1px solid white; order: 1 }
    .doubleToggle .doubleToggleOption1 { border-radius: 0 5px 5px 0; order: 2 }
    .doubleToggle .doubleToggleOption0::after { border-radius: 5px 0 0 5px }
    .doubleToggle .doubleToggleOption1::after { border-radius: 0 5px 5px 0 }
    .doubleToggle .doubleToggleLabel0 { justify-content: flex-end; margin-right: 10px; order: 0 }
    .doubleToggle .doubleToggleLabel1 { justify-content: flex-start; margin-left: 10px; order: 3 }