/*
     ___      _   _               
    | _ )_  _| |_| |_ ___ _ _  ___
    | _ \ || |  _|  _/ _ \ ' \(_-<
    |___/\_,_|\__|\__\___/_||_/__/

    All the following style are applied
    to the button component.

    Table of contents:
      - # General
      - # Main button
      - # Sub button
      - # Regular button
      - # Toggle button
      - # Icon button
      - # Sizes

*/

/* # General */

    input[type="submit"].button { border: none; border-radius: 25px }
    .buttonWrapper {
        border-radius: 5px;
        display: flex;
        height: 44px;
        margin: 5px;
        max-width: 300px;
        position: relative;
        width: max-content;
    }
    .button {
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 45px;
        justify-content: center;
        line-height: normal;
        max-width: 300px;
        overflow: hidden;
        padding: 10px 25px;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        transition: .5s;
        width: max-content;
        z-index: 1;
    }
    .button a { color: unset; text-decoration: none }
    .button a::after { content: unset }   
    .button .btn_overlay { 
        bottom: -5px;
        left: -5px;
        position: absolute;
        right: -5px;
        top: -5px;
        transition: .35s;
        z-index: -1;
    }
    .buttonWrapper .buttonShadow,
    .buttonIconWrapper .buttonShadow {
        border-radius: 5px;
        bottom: 0;
        left: 0;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 0;
    }
    .buttonIconWrapper .buttonShadow { border-radius: 100% }

/* # Main button */

    .button.main:hover { box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1); transform: scale(1.05) translateY(-2px) }
    .button.main .icon { height: 20px; margin: 0 5px 0 -5px; width: 20px }
    .button.main input { display: none }
    .button.main .btn_overlay { transform: scaleX(1); transition: .5s; width: 200% }
    .button.main:hover .btn_overlay { left: -100% }

/* # Sub button */

    .button.sub:hover { color: white !important }
    .button.sub .btn_overlay { opacity: 0 }
    .button.sub:hover .btn_overlay { opacity: 1 }
    .button.sub .icon { height: 20px; margin: 0 5px 0 -5px; width: 20px }
    
    /* Negative */

    .button.sub.negative { box-shadow: 0 0 0 1px white !important; color: white !important }
    .button.sub.negative:hover{ color: black !important }
    .button.sub.negative .btn_overlay { background-color: white !important }

/* # Regular button */

    .buttonRegular {
        align-items: center;
        border-radius: 5px;
        color: var(--grey-50);
        cursor: pointer;
        display: flex;
        font-size: 13px;
        padding: 5px 10px;
        width: max-content;
        max-height: 40px;
    }
    .buttonRegular:hover { background-color: var(--grey-5) }
    .buttonRegular .icon { height: 20px; margin-right: 5px; width: 20px }

/* # Toggle button */

    .buttonToggle {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 12px;
        margin: 5px;
        min-width: 25px;
        position: relative;
    }
    .buttonToggle span { font-size: 12px; margin-right: 10px }
    .buttonToggle.label {
        border-radius: 5px;
        color: var(--grey-50);
        height: auto;
        margin: 0;
        max-width: 200px; 
        padding: 5px;
    }
    .buttonToggle.label:hover { background-color: var(--grey-5) }
    .buttonToggle.label .buttonToggle_label_text {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .buttonToggle input { display:none }
    .buttonToggle input + .slider:hover:before { transform: translateX(10px) }
    .buttonToggle input:checked + .slider:hover:before { transform: translateX(0) }
    .buttonToggle .slider { background-color: var(--error); display: flex; position: relative }
    .buttonToggle .slider.round { border-radius: 25px; height: 12px; min-width: 25px; width: 25px }
    .buttonToggle .slider:before {
        background-color: white;
        bottom: 3px;
        content: '';
        height: 6px;
        left: 5px;
        position: absolute;
        transition: .25s;
        width: 6px;
    }
    .buttonToggle input:checked + .slider { background-color: var(--success) }
    .buttonToggle input:checked + .slider:before { transform: translateX(10px) }
    .buttonToggle input + .slider::after {
        border-radius: 50px;
        bottom: 0;
        box-shadow: 0 0 10px var(--error);
        content: '';
        left: 0;
        opacity: .5;
        position: absolute;
        right: 0;
        top: 0;
    }
    .buttonToggle input:checked + .slider::after { box-shadow: 0 0 10px var(--success) }
    .buttonToggle .slider.round { border-radius: 25px }
    .buttonToggle .slider.round:before { border-radius: 50% }

/* # Icon button */

    .buttonIconWrapper { display: flex; height: 50px; margin: 5px; position: relative; width: 50px }
    .button.buttonIcon {
        border-radius: 100%;
        height: 50px;
        padding: 10px;
        width: 50px;
    }
    .button.buttonIcon { cursor: pointer }
    .button.buttonIcon:hover { box-shadow: 0 2px 20px 0 rgba(0,0,0,0.1); transform: scale(1.05) translateY(-2px) }

/* # Sizes */

    /* Small */

    .buttonWrapper.S { height: 34px }
    .buttonWrapper.S .button { font-size: 12px; height: 35px; padding: 5px 20px }
    .buttonIconWrapper.S { height: 35px; width: 35px }
    .buttonIconWrapper.S .button.buttonIcon { height: 35px; padding: 8px; width: 35px }

    /* Medium */

    .buttonIconWrapper.M { height: 40px; width: 40px }
    .buttonIconWrapper.M .button.buttonIcon { height: 40px; padding: 10px; width: 40px }