/*
     _____            _      _         _   _             
    |_   _|_ _ __ _  | |__ _| |__  ___| | | |__  _____ __
      | |/ _` / _` | | / _` | '_ \/ -_) | | '_ \/ _ \ \ /
      |_|\__,_\__, | |_\__,_|_.__/\___|_| |_.__/\___/_\_\
              |___/                                      
    
    All the following style are applied
    to the tag label box component.

*/
    .tagLabelBox { display: flex; flex-flow: row wrap; max-width: 500px }
    .tagLabel {
        background-color: var(--sub);
        border-radius: 5px;
        color: white;
        font-size: 12px;
        margin: 5px;
        max-height: 35px;
        max-width: 150px;
        overflow: hidden;
        padding: 7px 15px;
        position: relative;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: max-content;
    }
    .tagLabel.close { padding-right: 30px }
    .tagLabel .icon {
        cursor: pointer;
        padding: 4px;
        position: absolute;
        right: 5px;
        top: 3px;
    }