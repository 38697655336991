/*
     _____     _       
    |_   _|_ _| |__ ___
      | |/ _` | '_ (_-<
      |_|\__,_|_.__/__/

    All the following style are applied
    to the tabs component.

*/

.tabs {
    border-bottom: 1px solid var(--grey-10); 
    display: flex; 
    flex-direction: row; 
    justify-content: center;
    max-height: 45px;
    min-height: 45px;
    overflow: hidden;
    padding: 10px;
    position: relative;
    width: 100%;
}
.tabs .tab {
    align-items: center;
    color: var(--grey-50);
    cursor: pointer;
    display: flex;
    flex-direction: row nowrap;
    font-size: 12px;
    justify-content: center;
    margin: 0 5px;
    padding: 5px 10px;
    position: relative;
    text-align: center;
    z-index: 1;
}
.tabs .tab::after {
    background: var(--grey-90);
    border-radius: 5px;
    bottom: 0;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .25s;
    z-index: 0;
}
.tabs .tab.active::after { background: var(--sub) }
.tabs .tab.active { color: var(--sub) }
.tabs .tab:hover::after, 
.tabs .tab.active::after { opacity: 0.05 }
.tabs .tab .icon { height: 20px; margin-right: 10px; width: 20px }

.tabs .tab_title {
    position: absolute;
    font-size: 12px;
    opacity: 0;
    transition: 0.25s;
    margin-top: 10px;
    top: 100%;
    white-space: nowrap;
    padding: 5px;
    background-color: var(--sub);
    color: white;
    border-radius: 5px;
    pointer-events: none;
    transform: scale(0);
    transform-origin: top;
}
.tabs .tab:hover .tab_title { opacity: 1; pointer-events: all; transform: scale(1); }

    